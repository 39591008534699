import React, { Component } from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom'
import Image1 from '../../assets/sliderImage/1.jpg'
import Image2 from '../../assets/sliderImage/2.jpg'
import Image3 from '../../assets/sliderImage/3.jpg'
import S from './style'
export default class SimpleSlider extends Component {
    render() {
        const settings = {
            arrows: false,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            autoplay: true
        };
        return (
            <Slider {...settings}>
                <S.SlideWrap location="flex-start">
                    {/* <S.Img src={Image1} alt="" /> */}
                    
                    {/* <S.contentArea color="white" >

                        <h2>EN 671-1 ve EN 671-2 Standardına Uygun ve Belgeli Yangın Dolapları</h2>
                        <Link to="/category/yangindolaplari/0/subcategories">Detaylı bilgi için tıklayın ▶</Link>
                    </S.contentArea> */}
                    <S.Img src={"/images/category/yanginDolabi.jpg"} alt="" />
                    <S.Img src={"/images/products/tüp_yanda_dikey_tek_kapakli_yangin_dolaplari/image-110.png"} alt="" />
                    <S.Img src={"/images/products/cift_makarali_bezli_sistem_sahra_tipi_yangin_dolaplari/image-162.png"} alt="" />
                </S.SlideWrap>
                <S.SlideWrap location="flex-start">
                    {/* <S.Img src={Image1} alt="" /> */}
                    
                        {/* <S.contentArea color="white" >

                            <h2>Sıva Altı & Sıva Üstü Acil Aydınlatma Armatürleri</h2>
                            <Link to="/category/acil_aydinlatma_ve_yonlendirme_armaturleri/12/subcategories">Detaylı bilgi için tıklayın ▶</Link>
                        </S.contentArea> */}
                    <S.Img src={'/images/products/tek_kanat_yangin_kapilari/image-199.png'} alt="" />
                    <S.Img src={'/images/products/cift_kanat_yangin_kapilari/image-202.png'} alt="" />
                    <S.Img src={'/images/products/camli_yangin_kapilari/image-207.png'} alt="" />
                </S.SlideWrap>
                <S.SlideWrap location="flex-start">
                    {/* <S.Img src={Image1} alt="" /> */}
                    
                        {/* <S.contentArea color="white" >

                            <h2>Sıva Altı & Sıva Üstü Acil Aydınlatma Armatürleri</h2>
                            <Link to="/category/acil_aydinlatma_ve_yonlendirme_armaturleri/12/subcategories">Detaylı bilgi için tıklayın ▶</Link>
                        </S.contentArea> */}
                    <S.Img src={'/images/slider/yan.png'} alt="" />
                    <S.Img src={'/images/slider/yanyakin.png'} alt="" />
                    <S.Img src={'/images/slider/on.png'} alt="" />
                </S.SlideWrap>
                {/* <S.SlideWrap location="flex-end">
                    <S.Img src={Image2} alt="" />
                    <S.contentArea color="white" >
                        <h2>Güvenli Alanlar İçin</h2>
                        <p>Detaylı bilgi için tıklayın ▶</p>
                    </S.contentArea>
                </S.SlideWrap>
                <S.SlideWrap location="flex-start">
                    <S.Img src={Image3} alt="" />
                    <S.contentArea color="white" >
                        <h2>Kontrol </h2>
                        <p>Detaylı bilgi için tıklayın ▶</p>
                    </S.contentArea>
                </S.SlideWrap> */}
            </Slider>
        );
    }
}