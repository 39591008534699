import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Hastane(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Karabük Özel Medikar Hastanesi/Simya Mimarlık Ltd. Şti.",
  "Serik Devlet Hastanesi",
  "Özel Jinefem Kadın Sağlığı Polikliniği",
  "Özel Dünya Tıp Merkezi Hastanesi",
  "Kemer Devlet Hastanesi / Kemer",
  "Çamyuva Özel Sağlık Hizmetleri / Çamyuva",
  "Radon Tıp Merkezi",
  "Çallı Meydan Tıp Merkezi",
  "Lara Hastanesi / Kar - Yapı A.Ş.",
  "Anadolu Hastanesi - Antalya Şb.",
  "Anadolu Hastanesi - Belek Şb.",
  "Kemer Yaşam Hastanesi / Antalya",
  "Kemer Yaşam Hastanesi / Kemer",
  "Atatürk Devlet Hastanesi (SSK)",
  "Meditalya Tıp Merkezi (MTM Sağlık Hizm. Ltd. Şti.)",
  "Özel Antalya Kepez Tıp Merkezi",
  "Gastroentroloji Cerrahi Merkezi /Fener Mah-ANTALYA",
  "Uncalı Meydan Tıp Merkezi",
  "Antalya Meydan Tıp Merkezi",
  "Korkuteli Yaşam Hastanesi / Korkuteli",
  "Anadolu Hastanesi - Kemer Şb.",
  "Anadolu Hastanesi - Side Şb.",

]
export default Hastane;