import styled from "styled-components";

const S = {
    SlideWrap: styled.div`
    height: 350px;
    position: relative;
    display: flex !important;
    flex-direction: row;
    justify-content: ${props => props.location};
    align-items: stretch;
    align-content: stretch;
    background-color: white;
    z-index: 9;
    @media (max-width:648px){
        flex-wrap: wrap;
    }
    `,
    contentArea: styled.div`
        background-color: ${props => props.color};
        flex-basis: 33%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 20px;
        @media (max-width:648px){
            flex-basis: 100%;
    }
        h2,a{
                z-index:2;
                color:black;
                /* text-shadow: -2px 0px 7px rgba(0,0,0,0.91); */
                text-align:left;
                letter-spacing: 3px;
            }
            a{
                font-size:18px;
                font-weight: 500;
                text-decoration:none;
                color:#E52C12;
                transition: 200ms;
                &:visited{
                    color:#E52C12;
                }
                &:hover{
                    transition: 200ms;
                    font-weight: 700;
                }
            }
            h2{
                font-size: 32px;
                margin:0;
                text-align:left;
                @media (max-width:648px){
                    font-size: 24px;
                }
            }

    `,
    Img: styled.img`
    flex-basis: 33%;
    height: 80%;
    @media (max-width:648px){
        height: 30%;
    }
    object-fit:contain;
    /* position: absolute; */
    z-index:-10;
    `
}

export default S