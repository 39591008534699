import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import S from './style'

import { DoubleArrow } from '@mui/icons-material'
import { useParams } from 'react-router';
import data from '../../services/data';
import dataen from '../../services/dataen';
import { useEffect, useState } from 'react';
import Certification from '../../components/certification';
import { LocaleContext } from '../../locale-context';
import TranslateStatic from '../../locales/TranslateStatic';
export function SubCategory() {
  let { id, name } = useParams();
  const value = useContext(LocaleContext);
  console.log(value)
  const [result, setResult] = useState(false);
  useEffect(() => {
    if (value === "en") {
      let filterdata = dataen.find(element => element.category.id == id)
      setResult(filterdata)
      console.log(filterdata)

    } else if (value === "tr") {
      let filterdata = data.find(element => element.category.id == id)
      setResult(filterdata)
      console.log(filterdata)
    }
  }
    , [id,value])
  return (
    <S.Wrapper>
      <S.Top>
        <S.Header>
          {result && result.category.name}
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home"/></Link> > <TranslateStatic string="categoryHeader"/>
        </S.Span>
      </S.Top>
      <S.Sertifika>
        <Certification />
      </S.Sertifika>
      <S.Section>
        {result && result.category.subcategories.map((x, i) => {
          if (!x.hide)
            return <div className="item-wrapper">
              <div className="item-image">
                <img
                  src={x.imgsrc[0]}
                  // src={location.origin + x.category.imgsrc}
                  alt="" />
              </div>
              <article>
                <div>
                  <h2>{x.name}</h2>
                  <p>{x.content}</p>
                </div>
                <div>
                  {x.link && <Link to={x.link}><TranslateStatic string="categoryDetailButton" /> <DoubleArrow /></Link>}
                </div>
              </article>
            </div>
        })}
      </S.Section>
    </S.Wrapper>
  );
}
