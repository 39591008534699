
import { Link } from 'react-router-dom';
import S from './style'
// import Image1 from '../../assets/sertification/tse.svg'
import Certification from '../../components/certification';
import { ReactComponent as Tse } from '../../assets/sertification/tsechoose.svg'
import { ReactComponent as Ce } from '../../assets/sertification/ce.svg'
import Iso from '../../assets/sertification/isopng.png'
import TranslateStatic from '../../locales/TranslateStatic';
export function Services() {
  return (
    <S.Wrapper>
      <S.Top>
        <S.Header>
          <TranslateStatic string="servicesH2" />
        </S.Header>
        <S.Span>
          <Link to="./"><TranslateStatic string="home" /></Link> > <TranslateStatic string="servicesHeader" />
        </S.Span>
      </S.Top>
      <S.Sertifika>
        <Certification />
      </S.Sertifika>
      <S.Section>
        <h3><TranslateStatic string="servicesh3-1" /></h3>
        <p>
          <TranslateStatic string="servicesP1" />
        </p>
        <h3><TranslateStatic string="servicesh3-2" /></h3>
        <p>
          <TranslateStatic string="servicesP2" />
        </p>
        <h3><TranslateStatic string="servicesh3-3" /></h3>
        <p>
          <TranslateStatic string="servicesP3" />
        </p>
        <ul>
          <li><TranslateStatic string="servicesListItem1" /></li>
          <li><TranslateStatic string="servicesListItem2" /></li>
          <li><TranslateStatic string="servicesListItem3" /></li>
          <li><TranslateStatic string="servicesListItem4" /></li>
        </ul>
        <h3><TranslateStatic string="servicesh3-4" /></h3>
        <p>
          <TranslateStatic string="servicesP4" />
        </p>
      </S.Section>
    </S.Wrapper>
  );
}
