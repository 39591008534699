import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Image1 from '../../assets/sliderImage/1.jpg'
import Image2 from '../../assets/sliderImage/2.jpg'
import Image3 from '../../assets/sliderImage/3.jpg'
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic'
export default class References extends Component {
  render() {
    const settings = {
      arrows: false,
      dots: true,
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 2,
      speed: 100,
      rows:1,
      slidesPerRow:2,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <S.SlideWrap>
        <h2><TranslateStatic string="referancesH2"/></h2>
        <Slider {...settings}>
          <div>
            <img src="https://www.antalya.bel.tr/images/logo/antalya_abb_logo.png" alt="" />
          </div>
          <div>
            <img src="https://anadoluhastaneleri.com/design/logoo-02.png" alt="" />
          </div>
          <div>
            <img src="https://www.antalya.pol.tr/kurumlar/antalya.pol.tr/antalya_emniyet_logo.png" alt="" />
          </div>
          <div>
            <img src="https://www.asat.gov.tr/images/asat-logo.png" alt="" />
          </div>
          <div>
            <img src="https://cdn.freelogovectors.net/wp-content/uploads/2018/05/atsologo-antalya-ticaret-odasi.png" alt="" />
          </div>
          <div>
            <img src="https://www.ziraatbank.com.tr/SiteAssets/images/logo.png" alt="" />
          </div>
          <div>
            <img src="https://www.sunexpress.com/tr/wp-content/themes/sxs/public/images/logo-sunexpress.png" alt="" />
          </div>
          <div>
            <img src="https://www.petrolofisi.com.tr/assets/uploads/logo.svg" alt="" />
          </div>
          <div>
            <img src="https://www.kemer.bel.tr/Upload/File/2020/3/6/logo.jpg" alt="" />
          </div>
          <div>
            <img src="https://www.yasamhastaneleri.com/assets/images/logo2.gif" alt="" />
          </div>
          <div>
            <img src="https://www.ankutsan.com/admin/img/upload/logo/75321.png" alt="" />
          </div>
          <div>
            <img src="https://www.ant.gov.tr/images/ant/antlogo.png" alt="" />
          </div>
          <div>
            <img src="https://www.kgm.gov.tr/SiteAssets/images/kgm_logo.png" alt="" />
          </div>
          <div>
            <img src="https://www.delphinhotel.com/main_pics/pages/medium/logo-306.png" alt="" />
          </div>
          <div>
            <img src="https://kervansarayhotels.com.tr/images/logo.png" alt="" />
          </div>


        </Slider>
        <Link to="./references"><TranslateStatic string="referancesMoreButton"/></Link>
      </S.SlideWrap>
    );
  }
}