import React, { useState, useEffect } from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { HomePage } from './pages/Home';
import { PageTwo } from './pages/Page2';
import { Product } from './pages/Product';
import { Services } from './pages/Services';
import Header from './components/header'
import Footer from './components/footer';
import { Iletisim } from './pages/ContactUs';
import GlobalStyle from './globalStyle';
import { Reference } from './pages/References';
import { Category } from './pages/Category';
import { SubCategory } from './pages/SubCategory';
import { Cookie } from './pages/Cookie';
import { Cer } from './pages/Certification';
import { Catalog } from './pages/catalog';
import Routi from './routes'
import CookieConsent from 'react-cookie-consent'
import { LocaleContext } from "./locale-context";
import landChecker from './utils/langChecker';

const customHistory = createBrowserHistory();
export default () => {
  const [preferredLocale, setPreferredLocale] = useState("tr");
  function changeLanguage(event) {
    setPreferredLocale(event.target.value)
  };
  useEffect(() => {
    setPreferredLocale(landChecker);
  }, [])
  return <LocaleContext.Provider value={preferredLocale} >
    <BrowserRouter history={customHistory}>
      <GlobalStyle />
      <Header lang={preferredLocale} changeLanguage={changeLanguage}/>
      <Routi />
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Onayla"
        cookieName="İnteksyangin Cookie"
        style={{
          background: "#e52c12",
          borderRadius: '10px',
          width: '400px',
          margin: '20px',
          padding: '5px',
          color: 'white',
          zIndex: 111111111111,
          // backgroundImage: 'url(/static/media/navbg.00a811e3.svg)',
          backgroundSize: '100% 100%'
        }}
        buttonStyle={{ color: "white", borderRadius: '5px', fontSize: "13px", background: 'black', width: '100%', fontWeight: 'bold' }}
        expires={150}
      >

        Size daha iyi hizmet sunabilmek için çerezleri kullanıyoruz.
        <br></br>
        <a style={{ color: 'white' }} href="/cookie">Daha fazla bilgi...</a>

      </CookieConsent>
    </BrowserRouter>
  </LocaleContext.Provider>
}
// const App = () => (
//   <BrowserRouter history={customHistory}>
//     <GlobalStyle />
//     <Header />
//     <Routi />
//     <Footer />
//     <CookieConsent
//       location="bottom"
//       buttonText="Onayla"
//       cookieName="İnteksyangin Cookie"
//       style={{
//         background: "#e52c12",
//         borderRadius: '10px',
//         width: '400px',
//         margin: '20px',
//         padding: '5px',
//         color: 'white',
//         zIndex: 111111111111,
//         // backgroundImage: 'url(/static/media/navbg.00a811e3.svg)',
//         backgroundSize: '100% 100%'
//       }}
//       buttonStyle={{ color: "white", borderRadius: '5px', fontSize: "13px", background: 'black', width: '100%', fontWeight: 'bold' }}
//       expires={150}
//     >

//       Size daha iyi hizmet sunabilmek için çerezleri kullanıyoruz.
//       <br></br>
//       <a style={{ color: 'white' }} href="/cookie">Daha fazla bilgi...</a>

//     </CookieConsent>
//   </BrowserRouter>
// );

// export default App;