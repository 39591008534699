import React from 'react';
import S from './style'
import TranslateStatic from '../../locales/TranslateStatic'
function Footer(props) {
    return (
        <S.Wrapper>
            <S.Ust>
                <div>
                    <h2><TranslateStatic string="contactH2"/></h2>
                    <ul>
                        <li><b><TranslateStatic string="contactAddress"/></b> : Şafak Mah. 4509Sk. No:307/A Kepez / <b>ANTALYA</b></li>
                        <li><b><TranslateStatic string="contactMail"/></b> : info@inteksyangin.com </li>
                        <li><b><TranslateStatic string="contactPhone"/></b> : 0242 221 60 55</li>
                    </ul>
                </div>
                <div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3189.9114929577763!2d30.6340847!3d36.916380499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c391e309b14af9%3A0x8ef4e047476c36a3!2zxLBudGVrcyBZYW5nxLFu!5e0!3m2!1str!2str!4v1634162419880!5m2!1str!2str"
                        allowfullscreen="" loading="lazy">

                    </iframe>
                </div>
            </S.Ust>
            <div className="copyright">
                <span><TranslateStatic string="contactCopyright"/></span>
                <span><TranslateStatic string="contactWebAuthor"/> : <a href="https://pretotip.com/" target="_blank">Pretotip.com</a></span>
            </div>
        </S.Wrapper>
    );
}

export default Footer;