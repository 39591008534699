import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Petrol Ofisi Dolum Tesisleri",
  "Petrol Ofisi Bölge Md.",
  "Ramazan Küçükbaşkan Petrol / Yeniköy",
  "Azizoğlu Petrol (Varsak)",
  "Eyiler Petrol",
  "Güzelyalı Petrol",
  "Ateşoğulları Petrol",
  "İlk Patara Petrol / Gelemiş Köyü - Kaş",
  "Konurlar Petrol",
  "Yakamoz Petrol Ltd. Şti. / Antalya",
  "Şahinbey Petrol (Korkuteli)",
  "Hacıoğulları Petrol / Döşemealtı",
  "Kasapoğlu Petrol / Manavgat – Sorgun",
  "Gürsoylar Petrol / Kale - DENİZLİ",
  "İbrahim Ateş Petrol / Yeşilbayır",
  "Dikmen Shell İstasyonu / Serik",
  "S.P.A.R.K. Petrol / Serik",
  "S.S. Yeşilyayla Sulama Koop. / Korkuteli",
  "Saraçoğulları Petrol",
  "Mehmet Alp Petrol",
  "Baykara Petrol",
  "Berrak Petrol Ltd. Şti.",
  "Hal Birlik Petrol A.Ş.",
  "Sar-pet Petrol",
  "Halk Otobüsleri Petrol Ofisi",
  "Bozkan Petrol",
  "Çalışkanlar Petrol",
  "Büyükgebizler Petrol (Antalya)",
  "Avcılar Petrol",
  "Fidan Petrol",
  "Karatoy Petrol (Serik)",
  "Kepez Petrol Ltd. Şti. / Antalya",
  "Set Akaryakıt",
  "Abdullah Ege Petrol / Serik",
  "Kor-Pet Petrol / Korkuteli",
  "Soylular Petrol / Antalya",
  "Nazmi Aksu Petrol / Çobanhisar Köyü - Elmalı",
  "Özkan Petrol (Tekirova)",
  "Tombakoğlu Petrol / Karaöz",
  "Kemerci A.Ş. / Korkuteli",
  "Yıldız Petrol",
  "Özsan Petrol",
  "Gökoğlu Petrol",
  "Netay Enerji Petrol Ltd. Şti.",



]
export default Belediye;