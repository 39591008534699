import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Sun Ekspres Havayolları",
  "Atlas Jet Uluslararası Havacılık A.Ş.",
  "Onur Air Havayolları",
  "Usaş Uçak Servisi A.Ş.",
  "Mastur Turizm",
  "Aker Otobüs İşletmeleri",
  "İlayda Turizm",
  "Ben Tour Ltd. Şti.",
  "Apollon Turizm A.Ş.",
  "Güzel Antalya Tur. A.Ş. (Dakor Turizm)",
  "Tantur Seyahat Turizm / Belek",
  "Tantur Seyahat Turizm / Antalya",
  "Tantur Seyahat Turizm / Side",
  "Tantur Seyahat Turizm / Kemer",
  "Tantur Seyahat Turizm / Alanya",
  "Aquasun Turizm A.Ş.",
  "Emek Seyahat Turizm",
  "Agne Tur Turizm Ltd. Şti.",
  "Pamfilya Turizm",
  "Sergen Turizm Taşımacılık Ltd. Şti.",



]
export default Belediye;