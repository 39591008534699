import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto min-content;
    grid-template-areas:"headerleft headerright"
    "content content";
    @media (max-width:648px){
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
    `,
    Top:styled.div`
    grid-area: headerleft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:40px;
    align-items: left;
    background-color: #E52C12;
    @media (max-width:648px){
        padding:20px;
    }
    `,
    Header: styled.h1`
    color:white;
    font-size: 40px;
    background-color: #E52C12;
    margin:0;
    /* padding:40px 40px 10px 40px; */
    @media (max-width:648px){
        font-size: 16px;
    }
    `,
    Span: styled.div`
    color:white;
    font-size: 14px;
    background-color: #E52C12;
    margin:0;
    a{
        color:white;
        text-decoration: none;
    }
    /* padding: 10px 40px 40px 40px; */
    `,
    Section:styled.section`
    margin: 40px;
    grid-area: content;
    `,
    Sertifika:styled.div`
    grid-area: headerright;
    background-color: #E52C12;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding:40px;
    align-items: center;
    @media (max-width:648px){
        padding:20px;
    }
    svg,img{
        margin:5px;
        width:70px;
        height: 70px;
        @media (max-width:648px){
            width:30px;
        height: 30px;
    }}
    `
}

export default S