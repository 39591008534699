import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Astek mühendislik Ltd.Şti.",
  "Teraş Terzioğulları İnşaat A.Ş.",
  "Akkor Mühendislik Ltd. Şti. / Antalya Özdilek AVM",
  "İskar İnşaat A.Ş. / Bucak Toki İnşaatı",
  "Aydın İnşaat / Manisa - Salihli Toki İnşaatı",
  "Biltur İnş. Ltd. Şti. / Aydın - Bozdoğan Toki İnşaatı",
  "Karmeks Mühendislik Ltd. Şti. / İzmit Toki İnşaatı",
  "İnprodek A.Ş.",
  "Uzaş Oğuzlar İnşaat A.Ş.",
  "Paktaş İnşaat Ltd. Şti.",
  "Aktay Tuzim İnşaat A.Ş.",
  "Özkar İnşaat A.Ş.",
  "Çekisan İnşaat (Kapalı Yol - Halkbankası İnşaatı)",
  "Evkur Alışveriş Merkezi (Kapalı Yol)",
  "Ulu İnşaat Ltd. Şti.",
  "Tokiş İnşaat / Ömer Özdinç",
  "İşler Yapı İnşaat Malzemeleri Ltd. Şti.",
  "As Acarlar İnşaat Malzemeleri Ltd. Şti.",
  "Mehmet Şahinollar İnşaat Ltd. Şti.",
  "İmta İnşaat Ltd. Şti.",
  "Nil Mühendislik / Antalya Cezaevi İnşaatı",
  "Ascan Mühendislik Ltd. Şti.",
  "Isı-Tek Tesisat A.Ş.",
  "Proses Mühensislik Ltd. Şti.",
  "Kare İnşaat Ltd. Şti.",
  "Kiraz Termotek Tesisat Ltd. Şti.",
  "Logo İnşaat Ltd. Şti.",
  "Mecitoğlu İnşaat A.Ş.",
  "Daran Mühendislik Ltd. Şti.",
  "Talay İnşaat Ltd. Şti.",
  "Kiska İnş. A.Ş.",
  "Ülkerler İnşaat",
  "S.G.L. İnşaat Ltd. Şti.",
  "Burcu İnşaat Ltd. Şti.",
  "Ay-Kartal İnşaat Ltd. Şti.",
  "Azizoğlu İnşaat Ltd. Şti.",
  "Hacıarifoğlu İnşaat",
  "Taşkıran İnşaat",
  "Z & M İnşaat",
  "Güven İnşaat",
  "Matekart Mühendislik Ltd. Şti.",
  "Pınarlar İnşaat Ltd. Şti.",
  "Er-kan İnşaat A.Ş.",
  "Özgüntur İnşaat Ltd. Şti.",
  "Tayhan Yapı Malzemeleri",
  "Demirbaş İnşaat / Ördem İnşaat",
  "Ekpa İnşaat Ltd.Şti",
  "Beyoğlu İnşaat A.Ş.",
  "Analitik Mühendislik Ltd. Şti.",
  "Ms Mühendislik Ltd. Şti. / Çavdır Toki İnşaatı",
  "Aydın İnşaat / Balıkesir - Kepsut Toki İnşaatı",
  "Biltur İnş. Ltd. Şti. / İzmir - Urla Toki İnşaatı",
  "Hatipoğlu İnşaat Ltd. Şti.",
  "Antalya Karataş İnşaat Ltd. Şti.",
  "Çallıoğlu İnşaat A.Ş.",
  "Mustafa Yapan İnşaat Ltd. Şti. / Airport İş Merkezi",
  "Maviler İnşaat Ltd. Şti.",
  "Simya Mimarlık Ltd. Şti.",
  "TSO Mühendislik Ltd. Şti.",
  "İstikbal Mobilya / Öztürkler Kuyumculuk (Kapalı Yol)",
  "Özkaymaz İnşaat",
  "Karayvatlar İnşaat A.Ş. / Adoportisan İnşaat A.Ş.",
  "Isısan Mühendislik Ltd. Şti.",
  "Necm İnşaat Ltd. Şti.",
  "Kaya İnşaat Ltd. Şti.",
  "Uluç Plaza / Uluç Gayrimenkul Ltd. Şti.",
  "Teknik Tesisat Ltd. Şti.",
  "Sistes Mühendislik Ltd. Şti.",
  "Günergün İnşaat Ltd. Şti.",
  "Alsan İnşaat Ltd. Şti.",
  "Canel Tesisat Ltd. Şti.",
  "Şahinler Yapı Market / Aksu",
  "Girgiç Kardeşler Sıhhi Tesisat",
  "Sefa Sıhhi Tesisat",
  "Anadolu Yüksel Tesisat Ltd. Şti.",
  "Hilal Tesisat ve Yapı Malzemeleri Ltd. Şti.",
  "Elegan Mimarlık Ltd. Şti.",
  "Öz Pınarlar İnşaat",
  "İşgören İnşaat Ltd. Şti.",
  "Çil İnşaat Yapı Malzemeleri Ltd. Şti.",
  "Nazmi Dilan İnşaat Taahhüt",
  "Metro İnşaat Ltd. Şti.",
  "Kaya Tesisat",
  "Öz Yörük İnşaat",
  "Bilgin İnşaat A.Ş.",
  "Kaplan İnşaat Ltd. Şti.",
  "Öz Maviler İnşaat",
  "Taşköprü İnşaat",
  "İzgi İnşaat Ltd. Şti.",
  "Mutaş İnşaat A.Ş.",
  "Eriş İnşaat Ltd. Şti.",
  "Durmazlar İnşaat Ltd. Şti.",


]
export default Belediye;