import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
const data = [
    "Sueno Golf Otel/Belek",
    "Delphin De Luxe Otel / Kundu",
    "Delphin Diva Otel / Kundu",
    "Delphin İmperial/Alanya",
    "Kervansaray Otel / Lara",
    "Kervansaray Otel / Kundu",
    "Ottium Otel / Manavgat",
    "Vogue Hotel Avangarde Otel İnşaatı (Bayturan A.Ş.)",
    "Adam & Eve Otel  / Belek",
    "Club Megasaray / Belek",
    "Aktay Tuzim İnşaat A.Ş. / Ela Quality Otel",
    "Le Jardin Otel / Bana Tur. A.Ş. - Kemer",
    "Oyunsu Turizm A.Ş. / Water Planet",
    "Silence Beach Resort Otel / Surtur Tur. A.Ş.",
    "Silkar Golf Tur. Yat. A.Ş. / Lykia World Links Golf Otel",
    "Amara Wing Resort Otel / Kemer",
    "Aypel Turizm Ltd. Şti. / Belvista Otel",
    "Şampiyon Tatil Köyü / Beldibi",
    "Club Rixos Otel / Bodrum",
    "Grand Şeker Otel / Çolaklı-Manavgat",
    "Club Grand Aqua / Side (Sezer Turz. A.Ş.)",
    "Side Aqua Hotel / Manavgat",
    "Club Hotel Nena / Manavgat",
    "Melissa Garden Otel / Çolaklı",
    "Mirada Del Mar Otel / Kemer (Koni Tur. A.Ş.)",
    "Club Grand Side / Side (Sezer Turz. A.Ş.)",
    "Corinthia Club Otel / Tekirova",
    "Papilion Aysha Otel / Belek",
    "Exelcıor Corınthıa Otel / Manavgat",
    "Turan Prince Otel / Side",
    "Exelcior Corinthia Otel",
    "Rixos Villaları / Tekirova",
    "Daima Resort Otel (Ek-sa İnşaat Ltd. Şti.)",
    "Roma Beach Resort (Türkmen Tur. Ltd. Şti.)",
    "Adres Otel / Kemer",
    "Matiate Otel / Kemer",
    "Martı Myra Otel / Tekirova",
    "Aura Resort Otel / Flamingo Tur. Ltd. Şti.",
    "Club Med / Beldibi",
    "Xanta Resort Otel / Kumköy",
    "Palm Garden Otel / Kızılağaç",
    "Emirhan Villa Gizem Otel",
    "Emirhan Side Güneşi",
    "Emirhan Otel",
    "Emirhan Beach",
    "Emirhan Garden",
    "Simena Villaları / Çamyuva",
    "Nisos Varuna Otel / Belek",
    "İber Otel / Kızıot",
    "Belinda Otel / Titreyengöl",
    "Belinda Club / Kumköy",
    "Belinda Felicia Otel / Kızılot",
    "Belinda Family Otel / Kumköy",
    "Riva Club N Otel (GTİ Tur. İşl. Ltd. Şti.)",
    "Ant Royal Otel / Güllük - Antalya",
    "Gelidonya Otel / Kemer",
    "Garden Resort Otel / Kemer",
    "Naşira Sun Flower Otel",
    "Poseidon Otel / Belek",
    "Ambassador Otel",
    "Adalya Resort / Manavgat (Yörükoğlu Tur.Ltd. Şti.)",
    "Delphin Otel / Alanya",
    "Botanik Otel / Alanya",
    "Saphir Hotel/Ati Anadolu A.Ş",
    "Grand Paradise Otel (Türkmen Tur. Ltd. Şti.)",
    "Defne Star Otel / The Kumul Otel - Manavgat",
    "Seher Otel / Manavgat",
    "Corolla Otel İnşaatı (Polen Tur. A.Ş.)",
    "Larabaia Otel - Kundu / Sönmez Tur. A.Ş.",
    "Zafer İnş. A.Ş. / Mardan Palace Otel",
    "Özkar İnş. A.Ş. / Calista Luxury Resort Otel",
    "Carya Golf Otel / Belek",
    "Savoy CO Ltd. / Kıbrıs - Girne",
    "Club Phaselis / Göynük",
    "Amara Club Marine / Beldibi",
    "Amara Beach Resort Otel / Kızılağaç",
    "Kemer Resort Otel / Kemer",
    "Locenia Otel (Melse Turizm Ltd. Şti.)",
    "Best Western K-Han Otel / Antalya",
    "Flora Garden Beach Otel / Manavgat",
    "Beytur Otel / Konyaaltı",
    "Holıday Point Otel (Tulukhan Turizm)",
    "The Marmara Otel / Lara",
    "Mahperi Beach Otel / Alanya",
    "MC Arancia Resort Otel / Alanya",
    "Cesars Otel / Belek (Erberk Tur. A.Ş.)",
    "Turan Prince Otel / Kızılağaç",
    "Festival Otel / Tekirova",
    "Akba Otel / Sarıgerme - MUĞLA",
    "Club Golf Otel / Belek (Teoser A.Ş.)",
    "Falkon Otel / Lara",
    "Elegan Garden Otel / Kemer",
    "Önder Otel / Akşehir - KONYA",
    "Side Turizm Yat. A.Ş. / The Kumul Otel",
    "Robinson Nobilis Otel",
    "Gönül Palace Otel / Kemer",
    "Orien't Palace Resort Otel / Alanya",
    "Erkal Resort / Kemer",
    "İntifini Otel / Kemer",
    "Viking Otel / Kemer",
    "Feronia Hills Otel / Kemer",
    "Pırate's Beach Otel",
    "Attelia Tatil Köyü / Belek",
    "Villa Diana Otel / Beldibi",
    "Solim Otel / Kemer",
    "Villa Ataç Otel / Antalya",
    "Simena Park Otel / Çamyuva",
    "Eston Yapı A.Ş.-Eston Villaları / Kemer",
    "Flower Otel / Kemer",
    "Ant Marin Otel / Konyaaltı",
    "Şahin Pansiyon / Çıralı - Kemer",
    "Genç Pansiyon / Kemer",
    "Şima Otel / Kemer",
    "Minta Apart Otel",
    "Parle Mare Otel / Konyaaltı",
    "La'mer Otel / Kemer",
    "Kervan Residence Otel / Kemer",
    "Kemer Grand Beaty Otel",
    "Asdem Beach Otel / Kemer",
    "Ekol Otel / Kemer",

]
function Otels(props) {
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));
    return (
        <AccordionDetails>
            <Typography>
                <ul>
                    {data.map((x, i) => {
                        return <li>{x}</li>
                    })}
                </ul>
            </Typography>
        </AccordionDetails>
    );
}

export default Otels;