import styled from "styled-components";

const S = {
    SlideWrap: styled.div`
    margin:100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    @media (max-width:648px){
        margin:20px;
        }
    a{
        padding: 10px 20px;
        background-color: red;
        text-decoration: none;
        color:white;
        margin: 50px 0px;
        width: 250px;
        align-self: center;
        text-align:center;
        border-radius: 20px;
        transition: 500ms;
        &:hover{
            width:260px;
            transition: 500ms;
            opacity: .8;
        }
    }

    div{
        margin:10px 0px;
        img{
            width:250px;
            height: 50px;
            object-fit: contain;
            /* filter: grayscale(); */
            @media (max-width:648px){
        width: 70px;
            }
        }
    }
    h2,p{
        text-align:center;
            z-index:2;
            color:#019fe3;
            margin:20px;
            /* text-shadow: -2px 0px 7px rgba(0,0,0,0.91); */
        }
        p{
            font-size:18px;
            font-weight: 500;
        }
        h2{
            font-size: 42px;
            margin:20;
            @media (max-width:648px){
                font-size:30px;
            }
        }
    `,
    Img: styled.img`
    /* width:100%; */
    width: 100px;
    object-fit:cover;
    position: absolute;
    z-index:-10;
    @media (max-width:648px){
        width: auto;
            }
    `,
}

export default S