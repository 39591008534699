import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Antalya Tan Yangın güv.san.tic.ltd.şti.",
  "BursaTan Yangın güv.san.tic.ltd.şti.",
  "Fetih Yangın güvenlik",
  "Gürkan yangın güvenlik",
  "Akdeniz Yangın güvenlik",
  "Öztat yangın güvenlik",
  "FSM yangın güvenlik",
  "Avcı Yangın güvenlik",
  "Siper yangın güvenlik",
  "Güney Yangın San.tic.ltd.şti.",
  "Kıvılcım yangın güvenlik",
  "Adalya yangın güvenlik",
  "Pruva Yangın güvenlik",
  "Tanışman Yangın güvenlik",
  "Akgül Yangın güvenlik",
  "Sadim Yangın güvenlik",
  "Kutsan döküm ve imalat sanayi",
  "Çakır Yangın güvenlik",


]
export default Belediye;