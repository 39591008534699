import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    display:grid;
    width:100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60vh 60vh;
    @media (max-width:648px){
        grid-template-columns: 1fr;
        grid-template-rows: none;
    }
    a{
            color:white;
            text-decoration: none;
            &:visited{
                color:white;
            }
        }
    .box{
        background:white;
        /* border:1px solid black; */
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        @media (max-width:648px){
           padding: 40px;
        }
        
        &:hover{
            img{
                opacity: 0.8;
            }
        }
        img{
            position: absolute;
            width: 100%;
            height:100%;
            z-index:1;
            object-fit: cover;
        }
        h2,p{
            z-index:2;
            color:white;
            text-shadow: -2px 0px 7px rgba(0,0,0,0.91);
        }
        p{
            font-size:18px;
            font-weight: 500;
            padding:20px;
            text-align:center;
        }
        h2{
            font-size: 42px;
            margin:0;
        }
    }
`,
    Baslik: styled.h1`
    text-align:center;
    margin-bottom: 40px;
    font-size:40px;
    color:#E52C12;
    `
}

export default S