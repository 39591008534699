import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Hektaş Tarım A.Ş.",
  "Fitar Tarım",
  "Yücebaş Tarım",
  "Ataç Tarım",
  "Bioser Fide Üretim Ltd. Şti.",
  "Renezoğlu Tarım Ürünleri Ltd. Şti.",
  "Baydar Tarım",
  "Koruma Tarım",
  "WBT Tarım Ürünleri",
  "Syngenta Tarım",
  "İstanbul Tarım",
  "Soylu Tarım",
  "Doruk Tarım",
  "AMC Tarım",
  "Bioser Fide",
  "Wegener Tarım",



]
export default Belediye;