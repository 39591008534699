import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Tabs from './tabs'
import TranslateStatic from '../../../locales/TranslateStatic';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}>
           <TranslateStatic string="referencesAcc1"/>
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc1Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Otels/>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc2"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc2Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Belediye/>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc3"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc3Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Hastane/>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc4"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc4Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Insaat/>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc5"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc5Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Fabrika/>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc6"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc6Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Resmi/>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc7"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc7Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Banka/>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc8"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc8Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Tarim/>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc9"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc9Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Havayollari/>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc10"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc10Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Akaryakit/>
      </Accordion>
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc11"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc11Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Okul/>
      </Accordion>
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary aria-controls="panel12d-content" id="panel12d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc12"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc12Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Diger/>
      </Accordion>
      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary aria-controls="panel13d-content" id="panel13d-header">
          <Typography sx={{ width: '50%', flexShrink: 0 }}><TranslateStatic string="referencesAcc13"/></Typography>
          <Typography sx={{ color: 'text.secondary' }}><TranslateStatic string="referencesAcc13Summary"/></Typography>
        </AccordionSummary>
        <Tabs.Yanginguvenlik/>
      </Accordion>
    </div>
  );
}
