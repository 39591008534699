import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Antalya Devlet Meteoroloji İşleri Genel Müdürlüğü",
  "Antalya Emniyet Müdürlüğü",
  "Antalya Adliye Bölge  Müdürlüğü",
  "Antalya L Tipi Kapalı Cezaevi / Yeniköy",
  "Orman İşletme Müdürlüğü (Antalya)",
  "Orman İşletme Müdürlüğü (Serik)",
  "Asat Su İşletmeleri Müdürlüğü",
  "Yükseköğrenim Kredi ve Yurtlar Kurumu Böl. Müd.",
  "Karpuzkaldıran Askeri  Dinlenme Tesisleri",
  "Oymapınar Barajı (Manavgat)",
  "Güney Ant.Tur.Geliş.veAltyapı Birl.(GATAB)/Kemer",
  "Batı Akdeniz Tarımsal Araştırma Enstitüsü (BATEM)",
  "Karayolları 13. Bölge Müdürlüğü",
  "DSİ 13. Bölge Müdürlüğü",
  "Tekirova Belediyesi - Tekirova Kültür Merkezi İnşaatı",
  "Büyükşehir Belediyesi İtfaiye Daire Başkanlığı",
  "Kepez Hükümet Konağı",
  "ATSO Bölge Müdürlüğü",
  "Köy Hizmetleri Bölge Müdürlüğü (Antalya)",
  "Milli Parklar Koruma Bölge Müdürlüğü",
  "Çevre İl Müdürlüğü",
  "Nato Tesisleri",
  "Antalya Kültür Merkezi (AKM) / Altınportakal Vakfı",
  "Fethi Bayçın Huzurevi",
  "Vehbi Koç Vakfı Müzesi (Akmed)",
  "Kepez Su Ürünleri",
  "Tasviye İşletmeleri Şube Müdürlüğü / Liman - Antalya",
  "Demre Su Ürünleri / Demre",
  "Ulaştırma Bakanlığı Antalya Bölge Müdürlüğü",
  "Türkiye İş ve İşçi Bulma Kurumu",


]
export default Belediye;