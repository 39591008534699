import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Antalya Büyükşehir Belediyesi",
  "Belkıs Belediyesi",
  "Kemer Belediyesi",
  "Kadriye Belediyesi",
  "Serik Belediyesi",
  "Sahilkent Belediyesi",
  "Çamyuva Belediyesi",
  "Göynük Belediyesi",
  "Boğazkent Belediyesi",
  "Çandır Belediyesi",
  "Finike Belediyesi",
  "Abdurrahmanlar Belediyesi",
  "Kepez Belediyesi",
  "Varsak Belediyesi",
  "Belek Belediyesi",
  "Tekirova Belediyesi",
  "Elmalı Belediyesi",
  "Beldibi Belediyesi",

]
export default Belediye;