import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
function Belediye(props) {
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <AccordionDetails>
      <Typography>
        <ul>
          {data.map((x, i) => {
            return <li>{x}</li>
          })}
        </ul>
      </Typography>
    </AccordionDetails>
  );
}
const data = [
  "Doka Plastik A.Ş. / Organize Sanayi",
  "Seto A.Ş. / Organize sanayi",
  "Akyıldız Plastik / organize sanayi",
  "Sümbüloğlu makine / Organize sanayi",
  "Cam-SAŞ / Organize sanayi",
  "Çağlayan Sera Plastik Ltd. Şti.",
  "Fippol Fabrikası / Organize Sanayi",
  "Antbirlik İplik Fabrikası / Aksu",
  "Antbirlik Genel Müdürlüğü",
  "Bolbey Beypiliç Ltd Şti",
  "Yetkin Cam Sanayi / Organize Sanayi Bölgesi",
  "Eti Elektrometalurji A.Ş.-Ferrokrom (Kepezaltı)",
  "Albatros Tekstil",
  "Phaselis Tekstil Fabrikası",
  "Identy Tekstil Ltd. Şti.",
  "Eliykan Tekstil",
  "Çoban Tekstil",
  "Global Tekstil",
  "Antalya Strafor / Antalya Yağ Sanayi A.Ş.",
  "Artmim Ltd. Şti.",
  "Akdeniz Ahşap Ürünleri A.Ş.(Organize Sanayi)",
  "Novamed GMBH / Serbest Bölge",
  "Günlas A.Ş.",
  "Alternatif Plastik / Organize Sanayi",
  "Ankutsan Antalya Kutu A.Ş./Organize Sanayi",
  "Motorsan A.Ş. / Organize sanayi",
  "NN Metalurji / Organize sanayi",
  "Adam plastik / Organize sanayi",
  "Demagro Tarım / Organize sanayi",
  "Yörükoğlu A.Ş./ Organize sanayi",
  "Falez Çelik Kapı (Bakırcılar Kapı Ltd. Şti.)",
  "Antalya Alkollü İçecekler Fabrikası A.Ş.",
  "Ado Pen A.Ş.",
  "Teknomer Marble A.Ş./ Bucak OSB",
  "Alanyalı Ahşap ve mobilya / Organize Sanayi",
  "Yektaş İplik Fabrikası",
  "Kimta A.Ş. / Organize Sanayi Bölgesi",
  "Antpor İnşaat Ltd. Şti. / Organize Sanayi",
  "Evoteks Tekstil",
  "Ayzer Tekstil",
  "Ser et Ltd.Şti",
  "Akdeniz Un Fabrikası / Akçay - Elmalı",
  "Kiwis Tekstil",
  "Genyapı A.Ş./Gen Bio Enerji A.Ş.-Organize Sanayi",
  "Günsu A.Ş.",
  "Akdeniz Orman Ürünleri A.Ş.(Kumluca)",
  "Sardunya Ahşap Ürünleri Ltd. Şti.",
  "Diler Boya ve Kimya San. A.Ş.",
  "Torbalı Renk Kutu / Organize Sanayi",
  "Günsu Su Kimyasalları A.Ş./Organize Sanayi",


]
export default Belediye;