import styled from "styled-components";
import { Link } from "react-router-dom";
const S = {
    Wrapper: styled.section`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-template-areas:"headerleft headerright"
    "content content";
    @media (max-width:648px){
        grid-template-columns: 1fr;
    }
    `,
    Top: styled.div`
    grid-area: headerleft;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:40px;
    align-items: left;
    background-color: #E52C12;
    @media (max-width:648px){
        padding:20px;
    }
    `,
    Header: styled.h1`
    color:white;
    font-size: 40px;
    background-color: #E52C12;
    margin:0;
    @media (max-width:648px){
        font-size: 16px;
    }
    /* padding:40px 40px 10px 40px; */
    `,
    Span: styled.div`
    color:white;
    font-size: 14px;
    background-color: #E52C12;
    margin:0;
    a{
        color:white;
        text-decoration: none;
    }
    /* padding: 10px 40px 40px 40px; */
    `,
    Sertifika: styled.div`
    grid-area: headerright;
    background-color: #E52C12;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding:40px;
    align-items: center;
    @media (max-width:648px){
        padding:20px;
    }
    svg,img{
        margin:5px;
        width:70px;
        height: 70px;
        @media (max-width:648px){
            width:30px;
        height: 30px;
    }}
    `,
    Section: styled.section`
    margin: 40px;
    grid-area: content;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: minmax(100px,auto);
    grid-gap: 40px;
    align-content: center;
    align-items: center;
    @media (max-width:648px){
        grid-template-columns: 1fr;
        margin:20px;
    }
    .item-wrapper{
        width: 100%;
        display: grid;
        box-sizing:border-box;
        flex-direction: row;
        /* justify-content: stretch; */
        /* align-content: stretch; */
        align-items: flex-start;

        /* height: 400px; */
        grid-template-columns:400px 1fr;
        grid-template-rows: minmax(20px,auto);
        grid-template-areas: 
            "images detail";
        gap: 40px;
        padding: 40px;
        /* background-color: gray; */
        /* -webkit-box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16); 
        box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16); */
        border: 2px solid #e5e5e5;
        border-radius: 10px;
        transition: 200ms;
        @media (max-width:648px){
            display: flex;
            padding:20px;
            gap: 0px;
        }
        p{
            &:nth-child(1){
                grid-area: header;
            }
        }
        .detail{
            grid-area: detail;
            display: grid;
            grid-template-columns:1fr;
            grid-gap: 10px;
            grid-template-areas: 
            "header"
            "tables"
            "figfull"
            ;
            @media (max-width:648px){
                /* grid-template-columns:1fr;
                gap: 5px; */
                display: flex;
                flex-direction: column;
                margin-top:50px;
            }
            h2{
                &:first-child{
                    grid-area: header;
                }
            }
            figure{
                /* grid-area: fig; */
                border:1px solid grey;
                border-radius: 4px;
                padding:10px;
                margin:5px 0px;
                /* &:first-of-type{
                    grid-area: tables;
                } */
                &:last-child{
                    grid-area: tables;
                }
                /* &:first-child{
                    grid-area: tables;
                    @media (max-width:648px){
         
                        grid-area: figfull;
                    }
                } */
                figcaption{
                    font-weight: bold;
                    /* color:#e62c12; */
                    color:white;
                    background-color: #e62c12;
                    padding:5px;
                    border-radius: 4px;
                    span{
                        color:black;
                    }
                }
                table {
                    margin:10px 0px;  
                    display: grid;
                    /* grid-gap: 5px; */
                    grid-template-columns: repeat(auto);
                    grid-template-rows: repeat(auto);
                    grid-template-areas: "header"
                    "column"
                    ;
                    border-collapse: collapse;
                    /* border:1px solid red; */
                    thead{
                        grid-area: header;
                        tr{
                            display: grid;
                            grid-template-columns: repeat(auto-fit,minmax(50px, 1fr));
                            grid-template-rows: auto;
                            th{
                                border-collapse: collapse;
                                border:1px solid black;
                                text-align:center;
                                word-wrap: break-word;
                                word-break: keep-all;
                            }
                        }
                    }
                    tbody{
                        grid-area: column;
                        tr{
                            display: grid;
                            grid-template-columns: repeat(auto-fit,minmax(50px, 1fr));
                            grid-template-rows: auto;
                            word-wrap: break-word;
                            &:nth-child(odd){
                                background-color: #019fe3;
                                color:white;
                            }
                            td{
                                border-collapse: collapse;
                                border:1px solid gray;
                                text-align:center;
                                word-wrap: break-word;
                                word-break: keep-all;
                            }
                        }
                    }
                }
                img{
                    width:70px;
                    height: 70px;
                }
            }

        }
        /* &:hover{
            transition: 200ms;
            -webkit-box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16); 
            box-shadow: 9px 15px 22px 5px rgba(0,0,0,0.16);
            border: 0px solid #e5e5e5;
        } */
        .item-image{
            grid-area: images;
            /* width: 100%; */
            /* height: 100%; */
            /* height: 200px; */
            box-sizing:border-box;
            display: flex;
            flex-direction: column;
            /* flex-wrap: wrap; */
            justify-content: center;
            @media (max-width:648px){
               max-width:300px;
            }
            .custom-paging{
                bottom:-50px;
            }
            img{
                /* margin:20px; */
                &:first-child{
                    /* width: 100%; */
                    max-height: 400px;
                    margin: 0px auto;
                }
                width:50%;
                object-fit: contain;
                max-height: 300px;
                margin: 0px auto;
                /* height: 100%; */
            }
        }
        article{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-content:space-between;
            justify-content: space-between;
            align-items: flex-end;
            h2{
                text-align:left;
                margin: 0;
            }
            a{
                padding: 20px;
                /* color:white; */
                color:#e62c12;
                /* background-color: #e62c12; */
                border-radius: 5px;
                font-weight: 500;
                font-size: 20px;
                border:1px solid #e62c12;
                /* background-color: #e62c12; */
                background-color: white;
                transition: 200ms;
                font-family: 'Rubik';
                text-decoration:none;
                &:visited{
                    color:#e62c12;
                }
                svg{
                    vertical-align: middle;
                    transition: 200ms;
                }
                &:hover{
                    transition: 200ms;
                    background-color: #e62c12;
                    color:white;
                    svg{
                        transition: 200ms;
                        margin-left:10px;
                    }
                }
                @media (max-width:648px){
                    margin:20px;
                    
                }
            }
        }
        @media (max-width:648px){
            padding:20px;
            /* height: auto; */
            flex-direction: column;
        }
    }
    `,
    Items:styled.div`
    display: flex;
    flex-direction:row;
    justify-content: center;
    `,
    InItem:styled(Link)`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        text-align:center;
        text-decoration: none;
        color:black;
        margin-top:10px;
        border-radius:10px;
        padding: 2px;
        border:1px solid white;
        transition: 500ms;
        &:hover{
            transition: 500ms;
            border:1px solid red;
        }
        &:visited{
            color:black;
        }
    `
}

export default S