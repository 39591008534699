import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { HomePage } from './pages/Home';
import { PageTwo } from './pages/Page2';
import { Product } from './pages/Product';
import { Services } from './pages/Services';
import Header from './components/header'
import Footer from './components/footer';
import { Iletisim } from './pages/ContactUs';
import GlobalStyle from './globalStyle';
import { Reference } from './pages/References';
import { Category } from './pages/Category';
import { SubCategory } from './pages/SubCategory';
import { Cookie } from './pages/Cookie';
import { Cer } from './pages/Certification';
import { Catalog } from './pages/catalog';
const Roti = () => (
    <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/aboutus" component={PageTwo} />
        <Route path="/services" component={Services} />
        <Route path="/product/:catid/:subcatid/:link" exact component={Product} />
        <Route path="/references" component={Reference} />
        <Route path="/contactus" component={Iletisim} />
        {/* <Route path="/certifications" component={Cer} /> */}
        <Route path="/catalog" component={Catalog} />
        <Route path="/category" exact component={Category} />
        <Route path="/cookie" component={Cookie} />
        <Route path="/category/:name/:id/:index" exact component={SubCategory} />
    </Switch>
);


export default Roti;