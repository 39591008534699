
import { Link } from 'react-router-dom';
import Slider from '../../components/slider'
import Product from '../../components/product';
import References from '../../components/references';
import S from './style'
export function HomePage() {
  return (
    <S.Wrapper>
      <S.SlideShow>
        <Slider />
      </S.SlideShow>
      <Product/>
      <References/>

    </S.Wrapper>
  );
}
