import styled from "styled-components";
import Logo from '../../assets/logo.svg'
const S = {
    Wrapper: styled.footer`
    display: flex;
    flex-direction: column;
    z-index: 9;
    /* padding:40px; */
    .copyright{
        display: flex;
        padding: 20px 40px;
        flex-direction: row;
        justify-content: space-between;
        background-color: #e62c12;
        color:white;
        a{
            text-decoration: none;
            color:white;
            transition: 500ms ease;
            &:visited{
                color:white;
            }
            &:hover{
                color:black;
                transition: 500ms ease;
            }
        }
    }
    p{
    
    }
    `,
    Ust: styled.div`
    /* background-image: linear-gradient(white,white),
    url(${Logo}); */
    /* background-blend-mode: saturation; */
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* flex-direction: row; */
    /* justify-content: space-between; */
    grid-gap: 40px;
    margin-bottom: 40px;
    height: 300px;
    position: relative;
    @media (max-width:648px){
        grid-template-columns: 1fr;
            }
    &::after{
        content: "";
        background-size:cover;
        background-image: url(${Logo});
        position:absolute;
        top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;  
      /* filter: opacity(0.5); */
      filter: grayscale(1);
      opacity: 0.1;
    }
    &::before{
        filter: grayscale(0.5);
    }
    h2{
        color:#E52C12;
    }
    iframe{
        border: none;
        width: 100%;
        height: 100%;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            padding-top: 10px;
        }
    }
    `,
    Img: styled.img`
    width:100%;
    object-fit:cover;
    position: absolute;
    z-index:-10;
    `
}

export default S