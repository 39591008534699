import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import S from './style'
import Logo from '../../assets/logo.svg'
import { Category, Close, Menu } from '@mui/icons-material'
import data from '../../services/data';
import Translate from '../../locales/Translate'
import TranslateStatic from '../../locales/TranslateStatic'
import LangChanger from './LangChanger'
function Header(props) {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const [result, setResult] = useState(false);
    const [searchPanel, setSearchPanel] = useState(false);

    function flattenObject(ob) {
        let arr = [];
        ob.map((x, i) => {
            if (x.category.link) {
                arr.push({ 'link': x.category.link })
            }
            if (x.category.subcategories) {
                x.category.subcategories.map((j, s) => {
                    arr.push({ 'link': j.link, 'name': j.name })
                })
            }
        })
        return arr
        // var toReturn = {};

        // for (var i in ob) {
        //     if (!ob.hasOwnProperty(i)) continue;

        //     if ((typeof ob[i]) == 'object' && ob[i] !== null) {
        //         var flatObject = flattenObject(ob[i]);
        //         for (var x in flatObject) {
        //             if (!flatObject.hasOwnProperty(x)) continue;

        //             toReturn[i + '.' + x] = flatObject[x];
        //         }
        //     } else {
        //         toReturn[i] = ob[i];
        //     }
        // }
        // return toReturn;
    }
    function ara(value) {
        // var res = search(data, function (key, val) { // im looking for this key value pair
        //     return key === 'name' && val === value;
        //     // return val === value;
        // });
        console.log(value)
        let res = flattenObject(data);
        let res2 = res.filter(element => {
            if (element.link) {
                console.log(`/${value}/g`)
                if (element.link.match(`${value}`)) {
                    // do something
                    console.log(element)
                    return element
                }

            }
        });
        console.log(res2)
        if (res2.length > 0) {

            setSearchPanel(true)
        } else {
            setSearchPanel(false)
        }
        setResult(res2)
    }
    return (
        <S.Wrapper>
            <S.Logo src={Logo} alt="inteks logo" />
            <S.Nav click={click}>
                <li onClick={closeMobileMenu}><Link to="/"> <TranslateStatic string="home" /></Link></li>
                <li onClick={closeMobileMenu}><Link to="/category"> <TranslateStatic string="products" /></Link></li>
                {/* <li onClick={closeMobileMenu}><Link to="/aboutus">KURUMSAL</Link></li> */}

                <li onClick={closeMobileMenu} id="dropdown">
                    <TranslateStatic string="institutional" />
                    <div class="dropdown-content">

                        <Link to="/aboutus"><TranslateStatic string="aboutus" /></Link>
                        {/* <Link to="/certifications"><TranslateStatic string="certificates" /></Link> */}
                        <Link to="/catalog"><TranslateStatic string="catalog" /></Link>
                    </div>
                </li>
                <li onClick={closeMobileMenu}><Link to="/services"><TranslateStatic string="services" /></Link></li>
                <li onClick={closeMobileMenu}><Link to="/references"><TranslateStatic string="referances" /></Link></li>
                <li onClick={closeMobileMenu}><Link to="/contactus"><TranslateStatic string="contact" /></Link></li>
            </S.Nav>
            <S.Search>
                {/* <div className="lang">
                    <ul>
                        <li><a>TR</a></li>
                        <li>|</li>
                        <li><a>EN</a></li>
                    </ul>

                </div> */}
                <LangChanger lang={props.lang} changeLanguage={props.changeLanguage} />
                <input placeholder="⚫" type="text" onChange={(e) => ara(e.target.value)}
                onClick={(e) => { if (e.target.value) { setSearchPanel(true) } }} />
                <S.SearchDropdown display={searchPanel} onMouseLeave={() => setSearchPanel(false)}>
                    {result && result.map((x, i) => {
                        return <li><Link to={x.link}>{x.name}</Link></li>
                    })}
                    {/* {console.log(result)} */}
                </S.SearchDropdown>
            </S.Search>
            <S.MobileMenu onClick={handleClick}>
                {click ? (
                    <Close className="menu-icon" />
                ) : (
                    <Menu className="menu-icon" />
                )}
            </S.MobileMenu>
        </S.Wrapper>
    );
}

export default Header;