import styled from "styled-components";

const S = {
    Wrapper: styled.section`
    display:flex;
    width:100%;
    flex-direction:column;
    /* justify-content: space-between; */
    background-color:#DADADA;
    z-index: 9;
    `,
    SlideShow: styled.section`
    height: 50vh;
    z-index: 9;
    margin-bottom: 40px;
    `
}

export default S